.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    color: white !important;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
    color: white !important;
}

.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
    color: white !important;
}

.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    color: white !important;
}

.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    color: white !important;
}

.css-sldnni,
/*.css-1cw4hi4,*/
.css-10qkmc3,
.css-6exafu {
    color: white !important;
}

.custom-weekday-labels .MuiDayCalendar-weekDayLabel:nth-child(1)::after {
    content: "un";
}

.custom-weekday-labels .MuiDayCalendar-weekDayLabel:nth-child(2)::after {
    content: "on";
}

.custom-weekday-labels .MuiDayCalendar-weekDayLabel:nth-child(3)::after {
    content: "ue";
}

.custom-weekday-labels .MuiDayCalendar-weekDayLabel:nth-child(4)::after {
    content: "ed";
}

.custom-weekday-labels .MuiDayCalendar-weekDayLabel:nth-child(5)::after {
    content: "hu";
}

.custom-weekday-labels .MuiDayCalendar-weekDayLabel:nth-child(6)::after {
    content: "ri";
}

.custom-weekday-labels .MuiDayCalendar-weekDayLabel:nth-child(7)::after {
    content: "at";
}

.css-rpff29-MuiDateCalendar-root,
.css-9ufhes,
.css-8wxewx {
    height: 320px !important;
    width: 100% !important;
    padding: 0;
}
.css-10qkmc3 {
    justify-content: center !important;
}

.css-nk89i7-MuiPickersCalendarHeader-root,
.css-1dozdou {
    padding: 10px 3px 10px 12px !important;
}
.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    padding: 0px !important;
}

.css-i5q14k-MuiDayCalendar-header,
.css-1n2mv2k {
    justify-content: space-around !important;
}
.css-flbe84-MuiDayCalendar-weekContainer,
.css-mvmu1r {
    justify-content: space-around !important;
}
.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel,
.css-10qkmc3 {
    width: 36px !important;
    font-size: 13px !important;
    font-weight: bolder !important;
}
.css-168nijn-MuiPickersDay-root,
.css-9twsku {
    width: 36px !important;
    height: 36px !important;
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root,
.css-6exafu,
.css-jgls56-MuiButtonBase-root-MuiPickersDay-root,
.css-15a0tn7 {
    width: 36px !important;
    height: 36px !important;
    z-index: 1000 !important;
}

.css-18wn1yb-MuiButtonBase-root-MuiPickersDay-root {
    width: 36px !important;
}

.css-144dnr3-MuiBadge-badge,
.css-qstccq,
.css-5u2lav {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    min-width: 36px !important;
    height: 36px !important;
    padding: 0px !important;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),
.css-15a0tn7:not(.Mui-selected) {
    border: 1px solid white !important;
    color: white !important;
}
.css-18wn1yb-MuiButtonBase-root-MuiPickersDay-root,
.css-l4pl75-MuiButtonBase-root-MuiPickersDay-root,
.css-15oafj3,
.css-1kp38pk {
    color: white !important;
    opacity: 0.5;
    z-index: 1000;
}
.css-1vs7z2v-MuiYearCalendar-root,
.css-1386ez6-MuiMonthCalendar-root,
.css-1bvpx9d,
.css-1k9sy1y {
    width: 100% !important;
}
.css-sf5j28-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
    color: white !important;
}

/**** Modal CSS *****/
.calendar-all-events-modal .modal-dialog {
    top: 50%;
    transform: translate(0%, -50%) !important;
}
.calendar-all-events-modal .modal-content {
    padding: 0px;
}
.calendar-all-events-modal .modal-header {
    border-bottom: 1px solid lightgrey !important;
}
.calendar-all-events-modal .modal-body {
    flex-direction: column !important;
}
.completed-session-event {
    background-color: #8794ef !important;
    color: black !important;
}
.speaker-meeting-event {
    background-color: #c2a44e !important;
    color: black !important;
}
.previous-session-event {
    background-color: rgb(78, 128, 245) !important;
    color: white !important;
}
.coach-meeting-event {
    background-color: cornflowerblue !important;
}

.css-14iq4xa-MuiDayCalendar-root {
    padding: 0 5px;
}
@media (max-width: 991px) {
    .calendar-all-events-modal .modal-body {
        padding: 15px !important;
    }
}

@media (max-width: 1200px) {
    .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root,
    .css-6exafu,
    .css-jgls56-MuiButtonBase-root-MuiPickersDay-root,
    .css-15a0tn7 {
        width: 30px !important;
        height: 30px !important;
        z-index: 1000 !important;
    }
    .css-18wn1yb-MuiButtonBase-root-MuiPickersDay-root,
    .css-l4pl75-MuiButtonBase-root-MuiPickersDay-root,
    .css-15oafj3,
    .css-1kp38pk {
        width: 30px !important;
        height: 30px !important;
    }
}

.calendar-footer__live-events-list {
    background-color: #3d4b7a;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 1rem;
}

.calendar-footer__live-events-list__item {
    border-bottom: dashed 1px white;
    padding-bottom: 1rem;
}

.calendar-footer__live-events-list__item {
    border-bottom: none;
    padding-bottom: 0;
}