.global-search__main-title__search-container__search-input {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    padding: 0.5rem 2rem;
    background-color: rgb(59, 71, 112);
    border: 3px solid rgba(37, 45, 71, 1);
    color: white;
    max-width: 200px;
}

.global-search__main-title__search-container__search-input::placeholder {
    color: rgba(219, 219, 255, 1);
    font-size: 14px;
}

.global-search__main-title__search-container__search-icon {
    position: absolute;
    left: 10px;
    color: rgba(219, 219, 255, 1);
    font-size: large;
}

.global-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}