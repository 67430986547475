.user-profile-achievements {
    border-radius: 20px;
    background-color: rgba(37, 45, 71, 1);
}

.user-profile-achievements__item {
    margin-right: 1rem;
    max-width: 6rem;
}

.user-profile-achievements__item:last-child {
    margin-right: 0;
}

.user-profile-achievements__item__logo {
    width: 4rem;
    height: 4rem;
}