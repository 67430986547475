.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    background-color: rgba(37, 45, 71, 1);
}

.pagination__pages {
    display: flex;
}

.pagination__pages__page {
    color: rgba(219, 219, 255, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 12px;
}

.pagination__scroll-button {
    cursor: pointer;
    display: flex;
    color: #FFFFFF;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid rgba(28, 34, 55, 1);
    background-color: rgb(59, 71, 112);
}

.pagination__scroll-button__first {
    margin-right: 8px;
}

.pagination__pages__page.active-white {
    background: rgba(139, 0, 50, 1);
    color: #FFFFFFFF;
}

.pagination__pages__page.white {
    color: #FFFFFFFF;
}

@media (max-width: 600px) {
    .pagination {
        padding: 12px 16px;
    }
    .pagination__scroll-button__text {
        display: none;
    }
    .pagination__scroll-button__first {
        margin-right: 0;
    }
    .pagination__pages__page {
        height: 30px;
        width: 30px;
        padding: 0;
        border-radius: 6px;
    }
}

@media (max-width: 380px) {
    .pagination {
        padding: 8px 12px;
    }

    .pagination__pages {
        flex-wrap: wrap;
        justify-content: center;
    }

    .pagination__pages__page {
        height: 20px;
        width: 20px;
        padding: 0;
        border-radius: 4px;
    }
}