.single-lesson-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 3fr 1fr;
}

.single-lesson-content {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 10px;
    height: max-content;
}

.other-lessons {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 10px;
    height: max-content;
}

.other-lessons__card {
    background-color: rgba(28, 34, 55, 1);
    margin-bottom: 1rem;
    cursor: pointer;
}

.other-lesson-thumbnail {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
}

.other-lessons__card:last-child {
    margin-bottom: 0;
}

.complete-lesson-btn {
    border-radius: 10px;
    background-color: #42c87f;
    color: white;
    outline: none;
    border: none;
    padding: 6px 8px;
}

.next-lesson-overlay {
    aspect-ratio: 16 / 9;
    width: 100%;
    border-radius: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1c2434;
}

@media (max-width: 768px) {
    .single-lesson-grid {
        grid-template-columns: 1fr;
    }
}