.master-class-lessons__wrapper {
    background-color: rgba(28, 34, 55, 1);
    border-radius: 10px;
}

.master-class-lesson__card {
    border-radius: 10px;
    background-color: rgba(28, 34, 55, 1);
    border: none!important;
}

.master-class-lesson__card:hover {
    background-color: #3d4b7a;
}

.master-class-lesson__card-thumbnail {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
}

.master-class-lesson__card__content {
    margin-left: 1rem;
}

.master-class__image {
    margin-left: 3rem;
}

.master-class__image img {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
}

.master-class__comments {
    margin-left: 3rem;
}

.master-class__speaker-info {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 10px;
    height: max-content;
    margin-left: 3rem;
}

@media (max-width: 991px) {
    .master-class__comments {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .master-class-lesson__card__content {
        margin-left: 0;
    }
    .master-class__image {
        margin-left: 0;
    }
    .master-class__speaker-info {
        margin-left: 0
    }
    .master-class__comments {
        margin-left: 0;
    }
}