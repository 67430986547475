.masterclass-progress-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.masterclass-progress-icons-grayscale {
    filter: grayscale(1);
}

.react-progress-bar-background {
    min-width: 60px;
}

.progressbar-count span {
    font-size: 11px !important;
    padding-right: 1rem !important;
}

.progressbar-count__hide-value span {
    display: none!important;
}
