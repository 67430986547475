.inner-card-layout-program {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 0.5rem;
    display: flex;
}

.program-masterclass-card {
    background-color: rgba(37, 45, 71, 1);
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.program-masterclass-card:last-child {
    margin-bottom: 0;
}

.program-masterclass-card:hover {
    background-color: #3d4b7a;
}

.program-masterclass-card-thumbnail {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
}

.program__image {
    margin-right: 3rem;
}

.program__image img {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
}

.program-card-description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.program-masterclass-card-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rating-title {
    font-size: 1rem;
    line-height: 1.5rem;
}

.overlay-completed {
    position: relative;
}

.overlay-completed::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5rem;
    z-index: 1;
}

.master-class-completed-opacity * {
    opacity: 0.3;
}

.program-completed-opacity * {
    opacity: 0.8;
}

@media (max-width: 991px) {
    .program-card-description {
        -webkit-line-clamp: 3;
    }
}

@media (max-width: 767px) {
    .program__image {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .program__content {
        margin-bottom: 0!important;
    }
}