.comment__author__profile-image {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.comment__author__name {
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin-left: 0.5rem;
}

.comment__time {
    margin-left: 0.75rem;
    font-size: .875rem;
    line-height: 1.25rem;
}

.comment__related-to {
    text-align: right;
    margin-left: 0.75rem;
    font-size: .875rem;
    line-height: 1.25rem;
}

.comment__related-to__link {
    color: rgba(219, 219, 255, 1);
}

.comment__reply__text {
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.25rem;
}

.comment__reply__icon {
    width: 14px;
    height: 14px;
}

.comment__title {
    margin-bottom: 0.5rem;
}

.comment__message-form {
    background-color: rgba(37, 45, 71, 1);
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid rgb(59, 71, 112);
}

.comment__message-form-reply {
    background-color: white;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.5rem;
}

.comment__message-form-reply textarea {
    background-color: white;
    appearance: none;
    border: none;
    color: rgba(37, 45, 71, 1);
}

.comment__message-form-reply textarea:focus {
    outline: none;
}

.comment__message-form textarea {
    background-color: rgba(37, 45, 71, 1);
    appearance: none;
    border: none;
    color: #ffffff;
}

.comment__message-form textarea:focus {
    background-color: rgba(37, 45, 71, 1);
    outline: none;
}

.comment__message-form textarea::placeholder {
    color: #9ca3a3;
}

.comments-list {
    overflow-y: auto;
    /*scrollbar-color: rgb(59, 71, 112) rgba(37, 45, 71, 1);*/
    /*scrollbar-width: thin;*/
}

.comments-list::-webkit-scrollbar {
    scrollbar-width: thin;
}

.comments-list::-webkit-scrollbar-thumb {
    background-color: rgb(59, 71, 112);
    border-radius: 100px;
}

.discussion-comment-wrapper:last-child {
    border: none!important;
}

.comment-actions__dropdown {
    background-color: rgb(59, 71, 112);
}

.comment-actions__dropdown__item {
    color: #ffffff;
}

.comment-actions__dropdown-toggle {
    background-color: inherit;
    border: none;
}

.comment-actions__dropdown-toggle::after {
    display: none;
}