body {
    width: 100%;
    height: 100%;
}
.row > * {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.toastify-progress-custom {
    background: #07BC0C !important;
}

.ql-editor {
    height: max-content;
    padding: 0;
}

.btn-primary-info {
    background-color: rgb(135, 148, 239);
    color: white;
}

.btn-primary-info:hover {
    background-color: rgb(170, 180, 245);
}