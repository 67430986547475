.pdf-preview-container {
    max-width: 100%; /* Ограничиваем ширину контейнера */
    max-height: calc(100vh - 100px); /* Устанавливаем максимальную высоту */
    overflow: auto; /* Скролл, если содержимое превышает размеры контейнера */
    padding: 10px;
    box-sizing: border-box;
}

.pdf-page-container {
    margin: 0 auto; /* Центрирование страницы */
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-pdf__Page__textContent {
    display: none !important;
}