.chat-container {
    display: flex;
    background-color: rgba(37, 45, 71, 1);
    height: 100%;
    box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.chat-list {
    width: 40%;
    border-right: 1px solid rgb(59, 71, 112);
    overflow-y: auto;
}

.chat-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-messages {
    pointer-events: auto;
    flex-grow: 1;
    overflow-y: auto;
}

#scrollableDiv::-webkit-scrollbar-thumb {
    background-color: rgb(59, 71, 112);
    border-radius: 100px;
}

#scrollableDiv::-webkit-scrollbar {
    scrollbar-width: thin;
}

.chat-list::-webkit-scrollbar-thumb {
    background-color: rgb(59, 71, 112);
    border-radius: 100px;
}

.chat-list::-webkit-scrollbar {
    scrollbar-width: thin;
}

.chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
}

.chats-toggle {
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0;
}

.chats-toggle:active,
.chats-toggle:focus {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.chat-recipient-avatar {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    object-fit: cover;
}

.chat-recipient-avatar__right {
    margin-left: 0.5rem;
}

.chat-recipient-avatar__left {
    margin-right: 0.5rem;
}

.contacts-list__item__last-message {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}