.nav-underline .nav-link,
.nav-underline .nav-link.active,
.nav-link:focus,
.nav-link:hover {
    border-bottom: transparent;
    font-size: 14px;
    cursor: pointer;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    padding-bottom: 10px;
    padding-right: 5px;
    margin-right: 20px;
    color: #b7b7fe;
}

.nav-underline .nav-link.active,
.nav-link:focus,
.nav-link:hover {
    border-bottom: #b7b7fe solid;
}

.nav-underline {
    border-bottom: rgb(183, 183, 254, 0.5) 0.5px solid;
}

.color-white {
    color: #fff;
}

.outer-card-layout {
    padding: 20px 20px;
}

.w-50 {
    width: 50%;
}

.gap-30 {
    gap: 30px;
}

.gap-20 {
    gap: 20px;
}

.align-center {
    align-items: center;
}

.select-text {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.select-d {
    background-color: #3d4b7a;
    color: #fff;
    border-color: rgb(97, 105, 134, 0.5);
    text-align-last: left;
    max-width: 200px;
}
.select-d:focus {
    box-shadow: none;
    outline: none;
    border-color: rgb(97, 105, 134, 0.5);
}

.date-input,
.date-input:focus {
    background-color: #3d4b7a;
    color: #fff;
    border-color: rgb(97, 105, 134, 0.5);
    text-align-last: left;
}

.date-input:focus {
    box-shadow: none;
    outline: none;
    border-color: rgb(97, 105, 134, 0.5);
}

.accordion-container {
    background-color: #3d4b7a;
    padding: 10px !important;
    border-radius: 10px;
    border-left: 4px #ff9494 solid;
}

.accordion-title {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}
.accordion-btn {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.justify-content-between {
    justify-content: space-between;
}

.accordion-question {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #b7b7fe;
}

.add-post {
    color: #dbdbff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: right;
}

.show-all-posts {
    background: #385f97;
    padding: 12px 16px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
}
.justify-end {
    justify-content: end;
}

.acc-message {
    align-items: start;
    margin-top: 2rem;
    gap: 20px;
}

.acc-message-question {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    color: #b7b7fe;
}

.acc-message-content {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #dbdbff;
}

.accord-yellow {
    border-color: #f8b805;
}

.add-new-project {
    border: #dbdbff 1px solid;
    height: 47px;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 10px;
}

.project-image {
    height: 270px;
    max-width: 100%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(73, 73, 73, 0), rgba(0, 0, 0, 1)),
        url("https://wappnet-systems.s3.us-west-1.amazonaws.com/public/catapult/cover_images/169649830857573.png");
}

.gap-20 {
    gap: 20px;
}

.image-text-span {
    font-size: 12px;
    margin-left: 5px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
}

.image-info {
    position: relative;
    bottom: 30px;
    padding: 0px 20px;
}

.project-name {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.3799999952316284px;
    text-align: left;
}

.project-author-name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 148, 148, 1);
}

.project-time {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(219, 219, 255, 1);
}

.project-detail {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
}

.justify-space-between {
    justify-content: space-between;
}

.back-button {
    border: rgba(139, 0, 50, 1) 1px solid;
    height: 47px;
    background-color: rgba(139, 0, 50, 1);
    border-radius: 10px;
    padding: 5px 20px 5px 10px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 10px;
    display: flex;
    align-items: center;
}
.project-container {
    background-color: rgba(55, 67, 104, 1);
    padding: 40px 30px;
    border-radius: 10px;
}

.project-title {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.3799999952316284px;
    text-align: left;
}

.project-heading {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
}

.project-description-1 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #dbdbff;
}

.img-round {
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 10px;
}

.share {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
}

.more-projects {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
}

.page-change-icon-left {
    background-color: #47588c;
    padding: 10px 8px;
    border-radius: 20px;
}

.page-change-icon-right {
    background-color: #47588c;
    padding: 6px 6px;
    border-radius: 20px;
}

.gap-10 {
    gap: 10px;
}

.brd {
    width: 45%;
    border-bottom: rgba(183, 183, 254, 0.5) 1px solid;
    height: 16px;
}

.comment-title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
}

.comment-container {
    border-radius: 10px;
    background-color: #374368;
    padding: 30px 20px;
}

.comment-user-name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    color: #ff9494;
}

.comment-time {
    color: #dbdbff;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
}

.comment {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    color: #dbdbff;
}

.btn-red {
    border: rgba(139, 0, 50, 1) 1px solid;
    height: 47px;
    background-color: rgba(139, 0, 50, 1);
    border-radius: 10px;
    padding: 10px 12px;
}

.btn-brown {
    border: #a07e59 1px solid;
    height: 47px;
    background-color: #a07e59;
    border-radius: 10px;
    padding: 10px 12px;
}

.height-340 {
    min-height: 340px;
}

.date-filter {
    justify-content: end;
}

.margin-lr {
    margin: 0px 10px;
}

.margin-lrb {
    margin: 0px 10px;
}

.heading {
    font-size: 25px;
    font-weight: 700;
    text-align: left;
}

.heading-2 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.pagination-div {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiPagination-root button {
    color: #b7b7fe;
}

.MuiPagination-root button.MuiPaginationItem-previousNext {
    background-color: #374368;
}

.MuiPagination-root button.Mui-selected {
    color: rgba(183, 183, 254, 1) !important;
    background-color: #374368;
}

.MuiPagination-root div {
    color: #b7b7fe;
}

.community-question-video {
    height: 300px;
    width: 550px;
    border-radius: 10px;
    object-fit: cover !important;
}

@media (max-width: 1399.98px) {
    .community-question-video {
        width: 100%;
        height: 300px;
    }
}

@media (max-width: 1199.98px) {
    .community-question-video {
        width: 100%;
        height: 300px;
    }
}

@media (max-width: 991.98px) {
    .margin-zero {
        margin: 0px;
    }
    .margin-lrb {
        margin: 0px 0px 10px 0px;
    }
    .back-button {
        width: 100%;
    }
    .height-340 {
        min-height: 100% !important;
    }
    .project-container {
        width: -webkit-fill-available;
    }
    .project-detail {
        margin-bottom: 10px;
    }
    .like {
        display: none !important;
    }
    .hide-mobile {
        display: none !important;
    }

    .date-filter {
        justify-content: start !important;
    }
    .community-question-video {
        width: 100%;
        height: 400px;
    }
}
@media (max-width: 767.98px) {
    .community-question-video {
        width: 100%;
        height: 300px;
    }
}

@media (max-width: 575.98px) {
    .community-question-video {
        width: 100%;
        height: 200px;
    }
}

.discussion-card-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.community__main-title {
    max-width: 720px;
}

.community__main-title__search-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.community__main-title__search-container__search-input {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    padding: 1rem 2rem;
    background-color: rgb(59, 71, 112);
    border: 3px solid rgba(37, 45, 71, 1);
    color: white;
}

.community__main-title__search-container__search-input::placeholder {
    color: rgba(219, 219, 255, 1);
}

.community__main-title__search-container__search-icon {
    position: absolute;
    left: 10px;
    color: rgba(219, 219, 255, 1);
    font-size: large;
    cursor: pointer;
}

.community-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.community-search__results {
    position: absolute;
    top: 80px;
    width: 100%;
    background-color: rgba(37, 45, 71, 1);
    border: 1px solid rgb(59, 71, 112);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1;
}

.community-search__results::-webkit-scrollbar {
    scrollbar-width: thin;
}

.community-search__results::-webkit-scrollbar-thumb {
    background-color: rgb(59, 71, 112);
    border-radius: 100px;
}

.search-result {
    padding: 12px;
    border-bottom: 1px solid rgb(59, 71, 112);
    cursor: pointer;
}

.search-result:last-child {
    border-bottom: none;
}

.search-result__title {
    font-size: 16px;
    margin: 0;
}

.search-result__category {
    font-size: 12px;
    color: #666;
}

.search-result:hover {
    background-color: #3d4b7a;
}

.search-result__item {
    color: rgba(219, 219, 255, 1);
}